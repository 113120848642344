import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

export type CarouselItemProps = {
  image: string;
}

type CarouselProp = { 
  carouselData: CarouselItemProps[]
}

export const FireCarousel: React.FC<CarouselProp> = ({carouselData}) => {

  return (
    <div className="flex items-center justify-center w-full min-h-xl bg-gray-100">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className="w-full max-w-full"
      >
        {carouselData.map((carousel,index) => (
            <SwiperSlide key={index}>
              <img src={carousel.image} width={1280} height={500} alt="Slide 1" className="w-full min-h-xl" />
            </SwiperSlide>
        ))}
      </Swiper>
  </div>
  )
}

export default FireCarousel